import './contact.css';

import React, { useRef, useState } from 'react';

import { MdOutlineEmail } from 'react-icons/md';
import { init, send } from '@emailjs/browser';
import { Helmet } from "react-helmet";
import IMG10 from "../../assets/justTalk.gif";
init("qUo59knwXbgSgW1cE");
const Contact = () => {
  const [message, setMessage] = useState(false);
  const formRef = useRef();

  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    details: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sending form data via EmailJS
    send('service_xf6swkm', 'template_z9wmkjy', {
      from_name: formData.name,
      from_email: formData.email,
      mobile: formData.mobile,
      message: formData.details,
      to_email: 'avinash@metxrstudios.com', // Your recipient email
    })
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text); // Log successful response
        setSuccessMessage("Details sent successfully!");
        setFormData({ name: "", email: "", mobile: "", details: "" }); // Reset form
      }, (err) => {
        console.error('FAILED to send email...', err); // Log any error
        setSuccessMessage("Failed to send details. Please try again.");
      });
  };
  const currentUrl = window.location.href;
  return (
    <section id="contact">
     <Helmet>
  <title></title>
  
  <meta name="description" content="Explore our cutting-edge AR, VR, and ERP solutions portfolio at MetXR Studios." />
  <meta name="robots" content="index, follow"/>
  <link rel="canonical" href={currentUrl} />

  <meta name="keywords" content="AR solutions, VR software, ERP tools, MetXR Studios, augmented reality, virtual reality,AR,VR,ERP,CRM,Met,XR,WebApp,Websites" />
</Helmet>

      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>contact@metxrstudios.com</h5>
            <a href="mailto:avinash@metxrstudios.com">Send a message</a>
          </article>
        </div>
        <form onSubmit={handleSubmit}>
        <input
                  type="text"
                  name="name"
                    placeholder="Your Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
           
                />
          <input
                  type="email"
                  name="email"
                    placeholder="Your Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
        
                />
              <input
                  type="text"
                  name="mobile"
                    placeholder="Your Mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                
                />
         <textarea
                  name="details"
                    placeholder="Comment"
                  value={formData.details}
                  onChange={handleInputChange}
                  rows={7}
                  required
                
                />
         <button type="submit" className="btn btn-primary liquid-btn" style={{ marginBottom: 40 ,color:"#ffffff"}}>
  <span>Send Message</span>
  <div></div>
</button>

          {message && <span>Thanks, I'll reply ASAP :)</span>}
        </form>
      </div>
    </section>
  );
};

export default Contact;
